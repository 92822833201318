<template>
	<div class="loginBox">
		<div class="w1200">
			<div class="loginContainer">
				<div class="loginTitle">立即登录</div>
				<div class="loginFormBox">
					<el-form ref="loginForm" :model="loginForm" :rules="loginRules" label-width="120px" label-position="top">
						<el-form-item label="手机号码:" prop="phone">
							<el-input v-model="loginForm.phone" type="text" auto-complete="off" placeholder="账号">
                <i slot="prefix" class="el-input__icon el-icon-phone" style="font-size: 1.6rem"></i>
              </el-input>
						</el-form-item>
            <el-form-item label="短信验证码" prop="code" class="code-input">
              <div class="flex_between codeNumBox" style="height: 40px;">
              	<el-input maxlength="6" placeholder="请输入验证码" v-model="loginForm.code">
              	  <i slot="prefix" class="el-input__icon el-icon-s-claim" style="font-size: 1.6rem"></i>
              	</el-input>
              	<div class="verify-btn-container">
              	  <el-button :disabled="isCanGetCode" type="primary" style="width: 100%;" @click="getNumCode">
              	    {{ isGetcode ? countDown : '' }}
              	    获取验证码
              	  </el-button>
              	</div>
              </div>
            </el-form-item>
						<!-- <el-form-item label="图片中验证码:" prop="codeImg" class="code-input" style="width: 100%;">
							<div class="flex_between" style="height: 40px;">
								<el-input v-model="loginForm.codeImg" onkeyup="value=value.replace(/[^\w\.\/]/ig,'')"
									auto-complete="off" placeholder="验证码" style="width: 63%;" @keyup.enter.native="handleLogin">
									<i slot="prefix" class="el-input__icon el-icon-s-claim" style="font-size: 1.6rem;"></i>
								</el-input>
								<div class="verify-btn-container">
									<img :src="codeUrl" style="height: 36px;cursor: pointer;" @click="getCodeNumImg" class="login-code-img" />
								</div>
							</div>
						</el-form-item> -->
						<el-form-item style="width: 100%">
							<el-button :loading="loginLoading" size="medium" type="primary" style="width: 100%"
					 		@click.native.prevent="handleLogin">
								<span v-if="!loginLoading">登 录</span>
								<span v-else>登 录 中...</span>
							</el-button>
						</el-form-item>
						<!-- <el-form-item style="width: 100%">
							<span class="whetherText">还没有账号？</span>
							<span @click="registerFun" class="registerText">点击进入注册页面</span>
						</el-form-item> -->

					</el-form>
				</div>
			</div>
		</div>


	</div>
</template>

<script>
	import Cookies from 'js-cookie'
	import { getSendCode,getCodeImg } from '@/api/login/login'
	import { mapGetters } from "vuex";
	export default {
		components: { },
		name: 'Login',
		data() {
			return {
				loginForm: {
					phone: "",
					code: "",
					uuid: "",
				},
				loginRules: {
					phone: [
            { required: true,trigger: 'blur',message: '手机号码不能为空' },
            {
              pattern: /^1[3|4|5|6|7|8|9][0-9]\d{8}$/,
              message: '请输入正确的手机号码',
              trigger: 'blur',
            }
          ],
					code: [{ trigger: "blur",required: true,message: "请输入验证码" }],
          codeImg: [{ trigger: "blur",required: true,message: "请输入图形验证码" }],
				},
				loginLoading: false,
				redirect: undefined,
				codeUrl: "",
        isGetcode: false,
        countDown: 60,
			}
		},
		computed: {
			...mapGetters({
				token: "token"
			}),
      isCanGetCode() {
        return !(
          (this.loginForm.phone && !this.isGetcode)
        );
      },
		},
		watch: {
			$route: {
				handler: function(route) {
					this.redirect = route.query && route.query.redirect
				},
				immediate: true,
			},
		},
		created() {
			if (this.token) {
				this.afterLogined();
			}
		},
		methods: {
      // 获取电话验证码
      getNumCode() {
        // 调用方法后 isGetcode  转为 true
        let phone = this.loginForm.phone;
        getSendCode({phone}).then((res) => {
          if (res.code == 200) {
            this.isGetcode = true;
            this.countDownFun();
            phone = null;
          }
        });
      },
      countDownFun() {
        if (this.countDown == 60) {
          let setTimeDo = null;
          let that = this;
          setTimeDo = setInterval(() => {
            that.countDown--;

            if (that.countDown == 0) {
              if (setTimeDo) {
                this.countDown = 60;
                clearInterval(setTimeDo);
                that.isGetcode = false;
              }
            }
          }, 1000);
        }
      },
			/* 跳转登录页 */
			afterLogined() {
				this.$message({ type: "error", message: "您已经登录"});
				this.$router.push({ path: "/home" });
        sessionStorage.setItem('activeIndex', "1")
			},
			/* 获取验证码 */
			getCodeNumImg() {
				getCodeImg().then(res => {
					this.codeUrl = "data:image/gif;base64," + res.img;
					this.loginForm.uuid = res.uuid;
				});
			},
			handleLogin() {
				this.$refs.loginForm.validate((valid) => {
					if (valid) {
            this.$store.dispatch('Login', this.loginForm).then(() => {
                this.$router.push({ path: this.redirect || '/' }).catch(() => {})
                sessionStorage.setItem('activeIndex', "1")
              })
              .catch(() => {
                this.loading = false
              })
						// this.$store.dispatch("Login", tempForm).then(() => {
						// 		this.$message.success('登录成功');
						// 		this.$router.push({ path: this.redirect || "/" })
						// 	}).catch(() => {
						// 		this.loginLoading = false;
						// 	});
					}
				});
			}
		},
	}
</script>

<style lang="scss">
	/* 两端对齐，项目之间的间隔都相等 */
	.flex_between {
		display: flex;
		justify-content: space-between;
	}

	.verticals {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}

	.line1 {
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}

	.w1200 {
		width: 1200px;
		margin: 0 auto;
	}

	.loginBox {
		width: 100%;
		min-height: 100vh;
		overflow: hidden;
		background-color: #f8fcff;
		padding-top: 50px;
	}

	.topBox {
		width: 100%;
		height: 60px;
		background: #0078d7;
	}

	.topTitle {
		text-align: right;
		line-height: 60px;
		font-size: 14px;
		font-family: Source Han Sans CN, Source Han Sans CN-Regular;
		font-weight: 400;
		color: #ffffff;
	}

	.logoBox {
		width: 100%;
		height: 120px;
		background: #ffffff;
		margin-bottom: 54px;
	}

	.logoImgBox {
		// width: 465px;
		height: 120px;
		display: flex;
		align-items: center;
	}

	.logoImg {
		width: 465px;
		height: 33px;
	}

	.logoTextBox {
		height: 120px;
		display: flex;
		align-items: center;
		font-size: 14px;
		color: #0078d7;
	}

	.logoTextBox span {
		cursor: pointer;
	}

	.homeImg {
		width: 16px;
		height: 16px;
		margin-right: 7px;
		cursor: pointer;
	}

	.loginContainer {
		width: 100%;
		overflow: hidden;
		background: #ffffff;
		border-radius: 8px;
		padding: 24px 40px;
		margin-bottom: 93px;
	}

	.loginTitle {
		height: 24px;
		font-size: 24px;
		font-family: Source Han Sans CN, Source Han Sans CN-Regular;
		font-weight: 400;
		color: #333333;
	}

	.loginFormBox {
		width: 450px;
		margin: 98px auto auto auto;
	}

	.code-input {
		::v-deep .el-form-item__content {
			display: flex;
			justify-content: space-between;
			width: 100%;
		}

		::v-deep .el-input {
			min-width: 60%;

			// width: 200px;
			// height: 48px;
			// line-height: 48px;
			// padding-right: 0;
			input[type="number"] {
				-moz-appearance: textfield;
			}

			input[type="number"]::-webkit-inner-spin-button,
			input[type="number"]::-webkit-outer-spin-button {
				-webkit-appearance: none;
				margin: 0;
			}

			.el-input__inner {
				border-radius: 4px 0 0 4px;
			}
		}

		.verify-btn-container {
			min-width: 40%;

			img {
				min-width: 100%;
			}

			.el-button {
				border-radius: 0 4px 4px 0;
				// border-radius: 10px;
			}
		}
	}
	.whetherText{
		font-size: 14px;
		font-family: Source Han Sans CN, Source Han Sans CN-Regular;
		font-weight: 400;
		text-align: left;
		color: #BFBFBF;
	}
	.registerText{
		font-size: 14px;
		font-family: Source Han Sans CN, Source Han Sans CN-Regular;
		font-weight: 400;
		text-align: left;
		color: #333333;
		cursor: pointer;
	}
  .codeNumBox {
    ::v-deep .el-input {
      min-width: 60%;
      input[type="number"] {
        -moz-appearance: textfield;
      }
      input[type="number"]::-webkit-inner-spin-button,
      input[type="number"]::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
      .el-input__inner {
        border-radius: 4px 0 0 4px;
      }
    }

    .verify-btn-container {
      min-width: 40%;
      img {
        min-width: 100%;
      }
      .el-button {
        border-radius: 0 4px 4px 0;
      }
    }
  }
	@media screen and (max-width: 1200px) {
		.w1200 {
			width: 100%;
			margin: 0 auto;
		}
		.loginContainer {
			padding: 10px 20px;
			margin-bottom: 23px;
		}
		.loginTitle{
			height: auto;
		}
		.loginFormBox{
			width: 100%;
			margin-top: 30px;
		}
	}
</style>
